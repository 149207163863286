/* istanbul ignore file */

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import store from "./store/index";
import App from "./app/App";
import { msalConfig } from "./common/components/auth/auth";
import "./app/App.scss";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { initializeIcons } from "@uifabric/icons";
import { loginRequest } from "./common/components/auth/auth";


export const pca = new PublicClientApplication(msalConfig);

pca.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    pca.setActiveAccount(account);
  }
});

pca.handleRedirectPromise().then(()=>{
  // Check if user signed in 
  const account = pca.getActiveAccount();
  if(!account){
    // redirect anonymous user to login page 
    pca.loginRedirect(loginRequest);
  }
}).catch(()=>{
  // TODO: Handle errors
});

// Register icons and pull the fonts from the default SharePoint cdn.
initializeIcons();

//router wrapper
// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <App msalInstance={pca}/>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
