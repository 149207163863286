/**
 *  Description: This file Stores App level Information about weather and Date.

 * @author {Cognizant Technology Solution}
 */

// @flow
import { createSlice } from "@reduxjs/toolkit";

const myFavoritesInitialState = {
  myFavoritesData: [],
};

// $FlowFixMe
const myFavoritesSlice = createSlice({
  name: "MYFAVORITES",
  initialState: myFavoritesInitialState,
  reducers: {
    myFavoritesData(state, action) {
      if(action.payload && action.payload.length>1){
        const resultsArray = JSON.parse(JSON.stringify(action.payload));
        resultsArray.sort(function (a, b) {
          return a.displayOrder - b.displayOrder;
        });
        state.myFavoritesData = resultsArray;
      } else {
        state.myFavoritesData = action.payload;
      }
    },
  },
});

export const myFavoritesActions = myFavoritesSlice.actions;
export default myFavoritesSlice.reducer;
