/**
 *  Description: This file counts and maintains the ratio total no. of sent request 
     to the total no.  of finished request.

 * @author {Cognizant Technology Solution}
 */

// @flow
import { createSlice } from "@reduxjs/toolkit";

const loadingInitialState = {
  finishedRequest: 1,
  totalRequest: 1
};

// $FlowFixMe
const loadingSlice = createSlice({
  name: "Loading",
  initialState: loadingInitialState,
  reducers: {
    getFinishLoadStatus(state) {
      state.finishedRequest = state.finishedRequest + 1;
    },
    getTotalRequest(state) {
      state.totalRequest = state.totalRequest + 1;
    }
  }
});

export const loadingActions = loadingSlice.actions;
export default loadingSlice.reducer;
