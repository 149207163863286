/**
 *  Description: This file stores the accessToken and  Refresh Tokens.

 * @author {Cognizant Technology Solution}
 */

// @flow
import { createSlice } from "@reduxjs/toolkit";

const userAuthState = {
  accessToken: "",
  msalToken: "",
  graph_token:"",
  user_email: "",
  welcomeBnr: {
    isUserWelBrnDisplay: true,
    lastSessionCloseTime: '',
  },
  header_footer:{}
};

// $FlowFixMe
const userAuthSlice = createSlice({
  name: "UserAuth",
  initialState: userAuthState,
  reducers: {
    accessToken(state, action) {
      localStorage.removeItem("apigee_token");
      state.accessToken = action.payload;
      localStorage.setItem("apigee_token", action.payload);
    },
    msalToken(state, action) {
      localStorage.removeItem("msalToken");
      state.msalToken = action.payload;
      localStorage.setItem("msalToken", action.payload);
    },
    graph_token(state, action) {
      state.graph_token = action.payload;
    },
    user_email(state, action) {
      state.user_email = action.payload;
    },
    isUserWelBrnDisplay(state, action){
      state.welcomeBnr.isUserWelBrnDisplay = action.payload
    },
    lastSessionCloseTime(state, action){
      state.welcomeBnr.lastSessionCloseTime = action.payload;
    },
    welcomeBnr(state, action){
      state.welcomeBnr = action.payload;
    },
    header_footer(state,action){
      state.header_footer=action.payload
    }
  }
});

export const userAuthActions = userAuthSlice.actions;
export default userAuthSlice.reducer;
