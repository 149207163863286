/**
 *  Description: This file Stores App level Information about weather and Date.

 * @author {Cognizant Technology Solution}
 */

// @flow
import { createSlice } from "@reduxjs/toolkit";
import {
  LOCATIONKEY,
  WEATHERDETAILS,
  MY_PREFERENCE,
  USER_WORLD_CLOCK_POST,
} from "../../constants/Constant";
import { toastActions } from "./ErrorToastReducer";
import {
  GETHOMEPAGEDETAILS,
  GETKITEHOMEDETAIL,
} from "../../constants/Constant";

const API_KEY = "47c3378e502947708faa48698e2fc5fd";
let locationValue;
const formatList = {
  Celsius: "C",
  Fahrenheit: "F",
  "HH:mm:ss A": 24,
  "h:mm:ss A": 12,
  "DD MM YYYY": "DD.MM.YYYY",
  "MM DD YYYY": "MM.DD.YYYY",
  "YYYY MM DD": "YYYY.MM.DD",
};
const dateTimeInitialState = {
  tempFormat: "Fahrenheit",
  dateFormat: "MM DD YYYY",
  clockFormat: "h:mm:ss A",
  temperatureData: {},
  defaultLocations: [{},{},{}],
  userPrimaryLocation : {name: "", lobCode: "", locationPS: ""},
  userPrimaryLocationRegion: {name: "America/Los_Angeles", code: "PST"},
  dataTimeCacheData: {},
  homePageDetails: {},
  kiteHomePageDetails: {},
  userPrimaryLocationList: []
};

// $FlowFixMe
const dateTimeSlice = createSlice({
  name: "DATETIME",
  initialState: dateTimeInitialState,
  reducers: {
    temperatureData(state, action) {
      state.temperatureData = action.payload;
    },
    tempFormat(state, action) {
      state.tempFormat = action.payload;
    },
    dateFormat(state, action) {
      state.dateFormat = action.payload;
    },
    clockFormat(state, action) {
      state.clockFormat = action.payload;
    },
    defaultLocations(state, action) {
      let sampleArray = state.defaultLocations;
      sampleArray.splice(action.payload.index, 1, action.payload.city);
      state.defaultLocations = sampleArray;
    },
    dataTimeCacheData(state, action) {
      state.dataTimeCacheData = action.payload;
    },
    homePageDetails(state, action) {
      state.homePageDetails = action.payload;
    },
    kiteHomePageDetails(state, action) {
      state.kiteHomePageDetails = action.payload;
    },
    userPrimaryLocationRegion(state, action) {
      state.userPrimaryLocationRegion = action.payload;
    },
    userPrimaryLocation(state, action){
      if(action.payload) {
        state.userPrimaryLocation.name = action.payload.name.toLowerCase().split(" ").join("");
        state.userPrimaryLocation.lobCode = action.payload.lobCode;
        state.userPrimaryLocation.locationPS = action.payload.locationPS;
      }  
    },
    userPrimaryLocationList(state, action) {
      state.userPrimaryLocationList = action.payload;
    }
  },
});

export const getLocationKey = (sendHttpRequest: any, city: any): any => {
  return () => {
    if(Object.keys(city || {}).length > 0){
      return sendHttpRequest({
        url: LOCATIONKEY.replace("LATITUDE", city?.latitudes)
          .replace("LONGITUDE", city?.longitudes)
          .replace("APIKEY", API_KEY),
        type: "WEATHER",
      }).then((data) => {
        return data[0];
      });
    }else{
      return null
    }

  };
};

export const getWeather = (sendHttpRequest: any, LocationKey: any): any => {
  return () => {
    return sendHttpRequest({
      url: WEATHERDETAILS.replace("LOCATIONKEY", LocationKey.Key).replace(
        "APIKEY",
        API_KEY
      ),
      type: "WEATHER",
    }).then((data) => {
      return data[0];
    });
  };
};

export const postDateTimeFormat = (sendHttpRequest: any, format: any): any => {
  return () => {
    const postData = {
      Result: [
        {
          clockFormat: formatList[format?.clockFormat],
          dateFormat: formatList[format?.dateFormat],
          temperatureScale: formatList[format?.tempFormat],
        },
      ],
    };
    return sendHttpRequest({
      url: MY_PREFERENCE,
      method: "POST",
      data: postData,
    });
  };
};

export const postLocation = (
  sendHttpRequest: any,
  cityObject: any,
  Order: Number
): any => {
  return (dispatch) => {
    const postData = {
      LocationId: cityObject?.locationId,
      LocationName: cityObject?.locationDisplayName,
      Order: Order,
    };
    return sendHttpRequest({
      url: USER_WORLD_CLOCK_POST,
      method: "POST",
      data: postData,
    })
      .then(() => {
        dispatch(toastActions.errorToast());
        dispatch(
          toastActions.errorMessage("World Clock Preference saved successfully")
        );
        dispatch(toastActions.errorBgColor("success"));
      })
      .catch(() => {
        dispatch(toastActions.errorToast());
        dispatch(
          toastActions.errorMessage(
            "There was an error while saving, Please try again"
          )
        );
        dispatch(toastActions.errorBgColor("danger"));
      });
  };
};

export const getHomePageDetails = (
  sendHttpRequest: any,
  location: any,
  userPrimaryLocation: any,
  lobCode: any,
): any => {
  return (dispatch, getState) => {
    const { defaultLocations, homePageDetails } = getState().dateTime;
    return sendHttpRequest({
      url: `${GETHOMEPAGEDETAILS.endpoint}?location=${location}&userprimarylocation=${userPrimaryLocation}&lobCode=${lobCode}`,
      isCache:
        GETHOMEPAGEDETAILS.isCaching && locationValue === defaultLocations[0],
      cachedData: homePageDetails,
      cacheTime: GETHOMEPAGEDETAILS.cacheTime,
    }).then((data) => {
      dispatch(dateTimeSlice.actions.homePageDetails(data?.Response || data));
      locationValue = defaultLocations[0];
    });
  };
};

export const getKiteHomePageDetails = (
  sendHttpRequest: any,
  location: any,
  userPrimaryLocation: any,
  lobCode: any,
): any => {
  return (dispatch, getState) => {
    const { defaultLocations } = getState().dateTime;
    return sendHttpRequest({url: `${GETKITEHOMEDETAIL}?location=${location}&userprimarylocation=${userPrimaryLocation}&lobCode=${lobCode}`,
    }).then((data) => {
      dispatch(
        dateTimeSlice.actions.kiteHomePageDetails(data?.Response || data)
      );
      locationValue = defaultLocations[0];
    });
  };
};

export const dateTimeActions = dateTimeSlice.actions;
export default dateTimeSlice.reducer;
