// @flow
import { createSlice } from "@reduxjs/toolkit";
const topBannerState = {
  topBannerData: [],
  topBnr: true,
};
// $FlowFixMe
const topBannerSlice = createSlice({
  name: "topBannerReducer",
  initialState: topBannerState,
  reducers: {
    updateTopBannerData(state, action) {
      state.topBannerData = action.payload;
    },
    topBnr(state, action) {
      state.topBnr = action.payload;
    },
  },
});
export const topBannerActions = topBannerSlice.actions;
export default topBannerSlice.reducer;
