/**
 * The page displays the error message when functionalities failed to integarte with backend.
 * @author {Cognizant Technology Solution}
 */

//@flow
import * as React from "react";
import { useHistory } from "react-router-dom";
import styles from "./ErrorPage.module.scss";

type Props = {
  fallbackUI: boolean,
  userNotFound: boolean
};
function ErrorPage(props: Props): any {
  /* UseHistory Function for navigate to another component */
  const history = useHistory();
  return (
    <div className={styles.errorPage}>
      <div className={styles.errorHeader}></div>
      <div className={styles.errorContent}>
        <h2 aria-level={2} data-testid='error-message'>
          {
            props.fallbackUI
              ? "Oops ! Something Went Wrong. Please try again"
              : (props.userNotFound ?
              "There was an error while attempting the operation." :
              "The page you're looking for doesn't exist.")
          }
        </h2>
        {!props.fallbackUI && (
            <span className={styles.para}>
              {!props.userNotFound ? <>{"Check for a typo in the URL, or"}{" "}</> : <>{"User not found, or"}{" "}</>}
              <span
                className={styles.homeLink}
                data-testid='errorPage'
                onClick={() => history.push("/")}
              >
                go to the site home
              </span>
            </span>
          )
        }
      </div>
    </div>
  );
}

export default ErrorPage;
