const expirationTimeGenerator = (token, expNeeded?) => {
  if (token) {
    token = JSON.stringify(token);
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    const start = new Date(Date.now());
    const end = new Date(JSON.parse(jsonPayload).exp*1000);
    if(expNeeded){
      return end;
    }
    const millis = end - start;
    return Math.floor(millis) - 120000;
  }
};

export default expirationTimeGenerator;
