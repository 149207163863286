/* istanbul ignore file */
import { React, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { USERPRIMARYLOCATION } from "../../../constants/Constant";
import useHttp from "../../../dataService/UseHttp";
import { dateTimeActions } from "../../reducers/DateTimeReducer";

function Progress() {
  const dispatch = useDispatch();
  const totalRequest = useSelector((state) => state.loading.totalRequest);
  const finishedRequest = useSelector((state) => state.loading.finishedRequest);
  const userPrimaryLocationList = useSelector(
    (state) => state.dateTime.userPrimaryLocationList
  );
  const userPrimaryLocation = useSelector(
    (state) => state.dateTime.userPrimaryLocation.name
  );
  useEffect(() => {
    if (userPrimaryLocationList?.length > 0 && userPrimaryLocation) {
      let CurrentLocationClock = JSON.parse(
        localStorage.getItem("CurrentLocationClock") || "{}"
      );
      if (Object.keys(CurrentLocationClock || {}).length > 0) {
        dispatch(
          dateTimeActions.defaultLocations({
            index: 0,
            city: CurrentLocationClock,
          })
        );
      } else {
        let usrPrmLoc =
          userPrimaryLocationList.filter((item) => {
            return (
              item.locationName ===
              userPrimaryLocation.toLowerCase().split(" ").join("")
            );
          }) || [];
        if (usrPrmLoc.length > 0) {
          usrPrmLoc = JSON.parse(JSON.stringify(usrPrmLoc));
          usrPrmLoc[0].order = 0;
          dispatch(
            dateTimeActions.defaultLocations({
              index: 0,
              city: usrPrmLoc[0],
            })
          );
        }
      }
    }
  }, [userPrimaryLocation, userPrimaryLocationList, dispatch]);

  const documentReadyState = () => {
    switch (document.readyState) {
      case "loading":
        break;
      case "interactive":
        break;
      case "complete":
        break;
      default:
    }
  };
  const [sendHttpRequest] = useHttp();
  useEffect(() => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; userPrimaryLocation=`);
    let answer = {};
    if (parts.length === 2) answer = JSON.parse(parts.pop().split(";").shift());
    if (Object.keys(answer || {}).length === 0) {
      sendHttpRequest({
        url: USERPRIMARYLOCATION,
        type: "LobCode",
      })
        .then((data) => {
            dispatch(
              dateTimeActions.userPrimaryLocation({
                name: data?.Response?.userPrimaryLocationName ||"fostercity" ,
                lobCode: data?.Response?.lobCode || "G",
                locationPS: data?.Response?.locationPS
                  ? data?.Response?.locationPS.toLowerCase().split(" ").join("")
                  : "",
              })
            );
            let now = new Date();
            let time = now.getDate();
            let cookieExpiryTime = parseInt(process.env.REACT_APP_UP_COOKIE_TIME);
            now.setDate(parseInt(time) + cookieExpiryTime);
            document.cookie =
              "userPrimaryLocation=" +
              JSON.stringify({
                name: data?.Response?.userPrimaryLocationName ||"fostercity" ,
                lobCode: data?.Response?.lobCode || "G" ,
                locationPS: data?.Response?.locationPS
                  ? data?.Response?.locationPS.toLowerCase().split(" ").join("")
                  : "",
              }) +
              ";expires=" +
              now.toUTCString() +
              ";path=/";
        })
        .catch(() => {
          dispatch(
            dateTimeActions.userPrimaryLocation({
              name: "fostercity",
              lobCode: "G",
              locationPS: "",
            })
          );
        });
    } else {
      dispatch(
        dateTimeActions.userPrimaryLocation({
          name: answer?.name,
          lobCode: answer?.lobCode,
          locationPS: answer?.locationPS
            ? answer?.locationPS.toLowerCase().split(" ").join("")
            : "",
        })
      );
    }
    // eslint-disable-next-line
  }, [dispatch, sendHttpRequest]);
  useEffect(() => {
    documentReadyState();
  }, []);

  useEffect(() => {
    let searchIdUpdate = setTimeout(() => {
      if ((+finishedRequest * 100) / +totalRequest === 100) {
        const container = document.querySelector(".container");
        if (container) {
          container.id = "crawlReady";
        }
      }
      documentReadyState();
    }, 3000);
    return () => {
      const container = document.querySelector(".container");
      if (container) {
        container.removeAttribute("id");
      }
      clearTimeout(searchIdUpdate);
    };
  }, [totalRequest, finishedRequest]);

  return <></>;
}
export default Progress;
