/* istanbul ignore file */
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID,
    authority:
      `https://login.microsoftonline.com/${process.env.REACT_APP_MSAL_TENANT_ID}`,
    redirectUri: "/",
    postLogoutRedirectUri: "/",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: () => {},
      logLevel: "Verbose",
    },
  },
};

export const loginRequest = {
  scopes: [`${process.env.REACT_APP_MSAL_CLIENT_ID}/.default`],
  domainHint: process.env.REACT_APP_MSAL_DOMAIN,
};
export const loginRequest2 = {
    domainHint: process.env.REACT_APP_MSAL_DOMAIN,
    scopes: ['https://graph.microsoft.com/.default']
  };

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
