/**
 *  Description: This file routes to login Page and also provides Navigation to
                  different Pages.

 * @author {Cognizant Technology Solution}
 */
/* istanbul ignore file */
// @flow
import * as React from "react";
import { Suspense } from "react";
import { Container } from "react-bootstrap";
import { Switch, Route } from "react-router-dom";
import { AuthenticatedTemplate } from "@azure/msal-react";
import ErrorPage from "../common/components/errorBoundary/errorPage/ErrorPage";
import { KITENETNEWSDETAILS, KITENET_NEWSROOM, KITE_NET, NEWSDETAILS, NEWS_ROOM, USER_PROFILE } from "../constants/Constant";

const NewsDetails = React.lazy(() =>
  import("../modules/home/newsDetails/NewsDetails")
);
const Home = React.lazy(() => import("../modules/home/Home"));
const KiteNet = React.lazy(() => import("../modules/kiteNet/KiteNet"));
const KiteNetNewsRoom = React.lazy(() =>
  import("../modules/kiteNetNewsRoom/KiteNetNewsRoom")
);
const KiteNewsDetails = React.lazy(() =>
  import("../modules/kiteNet/newsDetails/KiteNewsDetailsAPI")
);
const NewsRoom = React.lazy(() => import("../modules/newsRoom/newsRoom"));
const TopLayer = React.lazy(() => import("../modules/header/TopLayer"));
const BottomLayer = React.lazy(() => import("../modules/footer/BottomLayer"));
const UserProfileReq = React.lazy(() =>
  import("../modules/userProfilePage/userProfileReq")
);
const SearchResult = React.lazy(() =>
  import("../modules/searchResult/SearchResult")
);
const BookmarkModal = React.lazy(() =>
  import("../common/components/bookmark/BookmarkModal")
);
const WelcomeGnet = React.lazy(() => import("./../modules/welcomegnet/WelcomeGnet"));

function RouterNavigation() {

  return (
    <AuthenticatedTemplate >
      <Suspense fallback={<></>}>
        <Route path="/" component={TopLayer}></Route>
        <Container role="main">
          <Route path="/" component={BookmarkModal} />
          <Route path="/" component={WelcomeGnet} />
          <Switch>
            <Route path="/" component={Home} exact />
            <Route
              path={`${NEWSDETAILS}/:ItemName/:Preview?`}
              component={NewsDetails}
              exact
            />
            <Route path= {`${ NEWS_ROOM }/:Preview?`} component={NewsRoom} exact />
            <Route path={`${ USER_PROFILE }/:EmailId?/:Preview?`} component={UserProfileReq} exact />
            <Route path="/SearchResult" component={SearchResult} exact />
            <Route
              path={`${ KITENET_NEWSROOM }/:Preview?`}
              component={KiteNetNewsRoom}
              exact
            />
            <Route
              path={`${ KITENETNEWSDETAILS }/:ItemName/:Preview?`}
              component={KiteNewsDetails} 
              exact
            />
            <Route path={`${ KITE_NET }/:Preview?`} component={KiteNet} exact />
            <Route path="/Home/:Preview?" component={Home} exact />
            <Route path="*">
              <ErrorPage fallbackUI={false} userNotFound={false} />
            </Route>
          </Switch>
        </Container>
        <Route path="/" component={BottomLayer}></Route>
      </Suspense>
    </AuthenticatedTemplate>
  );
}

export default RouterNavigation;
