/**
 *  Description: This file contains store to combine all reducers

 * @author {Cognizant Technology Solution}
 */
import { configureStore } from "@reduxjs/toolkit";
import UserAuthReducer from "../common/reducers/UserAuthReducer";
import ErrorToastReducer from "../common/reducers/ErrorToastReducer";
import LoadingReducer from "../common/reducers/LoadingReducer";
import DateTimeReducer from "../common/reducers/DateTimeReducer";
import MyFavoritesReducer from "../common/reducers/MyFavoritesReducer";
import LikeBookmarkReducer from "../common/reducers/LikeBookmarkReducer";
import SearchResultReducer from "../common/reducers/SearchReducer";
import DashBoardReducer from "../common/reducers/DashBoardReducer";
import TopBannerReducer from "../common/reducers/TopBannerReducer";

/* Store & Combine Reducer Function for Every Reducer */
// $FlowFixMe
const store = configureStore({
  reducer: {
    userAuth: UserAuthReducer,
    errorToast: ErrorToastReducer,
    loading: LoadingReducer,
    dateTime: DateTimeReducer,
    myFavorites: MyFavoritesReducer,
    likeBookmark: LikeBookmarkReducer,
    searchResult: SearchResultReducer,
    myDashBoardModal : DashBoardReducer,
    topBanner: TopBannerReducer,
  },
});

export default store;
