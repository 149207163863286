/**
 *  Description: This file prompts the Error Message which gets displayed at top of the Page.

 * @author {Cognizant Technology Solution}
 */

// @flow
import { createSlice } from "@reduxjs/toolkit";

const modalInitialState = {
  errorToast: false,
  errorMessage: "",
  errorBgColor: "",
  errorPage: false
};

// $FlowFixMe
const toastSlice = createSlice({
  name: "TOAST",
  initialState: modalInitialState,
  reducers: {
    errorToast(state) {
      state.errorToast = !state.errorToast;
    },
    errorMessage(state, action) {
      state.errorMessage =
        action.payload; /* action.payload !== undefined ? action.payload : action.payload */
    },
    errorBgColor(state, action) {
      state.errorBgColor = action.payload;
    },
    errorPage(state) {
      state.errorPage = !state.errorPage;
    }
  }
});

export const toastActions = toastSlice.actions;
export default toastSlice.reducer;
