/**
 *  Description: This file stores the updated count and the State of the Like and Bookmark Button, contains dashboard changes.

 * @author {Cognizant Technology Solution}
 */

// @flow
import { createSlice } from "@reduxjs/toolkit";

const dashBoardState = { 
  userSelectedMyDashboardItems : [],
  dashboardCompanyItems : [],
  responseStatus : false,
  toggleModal:false,
};

// $FlowFixMe
const dashBoardModalSlice = createSlice({
  name: "dashBoardDataReducer",
  initialState: dashBoardState,
  reducers: {
    updateToggleModal(state,action) {
      state.toggleModal = action.payload;
    },
    updateResponseStatus(state,action){
      state.responseStatus = action.payload;
    },
    updateUserSelectedMyDashboardItems(state,action){
      state.userSelectedMyDashboardItems = action.payload;
    },
    updatedashboardCompanyItems(state,action){
      state.dashboardCompanyItems = action.payload;
    },
  },
});
export const dashBoardModalActions = dashBoardModalSlice.actions;
export default dashBoardModalSlice.reducer;
