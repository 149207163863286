/**
 *  Description: This file Stores App level Information about weather and Date.

 * @author {Cognizant Technology Solution}
 */

// @flow
import { createSlice } from "@reduxjs/toolkit";
import { peopleSearch, NEWSROOM } from "../../constants/Constant";

const SearchInitialState = {
  
  allgnetemployeeContracters: {
    results: [],
    count: null,
    payload: {
      pageSize: 7,
      pageNumber: 1,
      searchTerm: "",
      employeeType: "employeeContractor",
    },
    prevPayload:{},
  },
  allgnetbadgeOnlyWorkers: {
    results: [],
    count: null,
    payload: {
      pageSize: 3,
      pageNumber: 1,
      searchTerm: "",
      employeeType: "badgeonlyworker",
    },
    prevPayload:{},
  },
  badgeOnlyWorkers: {
    results: [],
    count: null,
    payload: {
      pageSize: 10,
      pageNumber: 1,
      searchTerm: "",
      employeeType: "badgeonlyworker",
      sortBy: 'Relevance',
    },
    prevPayload:{},
  },
  employeeContracters: {
    results: [],
    count: null,
    departmentlist: [],
    locationlist: [],
    payload: {
      pageSize: 10,
      pageNumber: 1,
      searchTerm: "",
      employeeType: "all",
      sortBy: 'Relevance',
      IsFilterby: true,
      reqDepartmentList: [],
      reqLocationlist: [],
    },
    prevPayload: {},
  },
  newsRoomResults: {},
  pagesDocuments: {}
};

// $FlowFixMe
const searchSlice = createSlice({
  name: "Search",
  initialState: SearchInitialState,
  reducers: {
    allgnetemployeeContracters(state, action) {
      state.allgnetemployeeContracters = action.payload;
    },
    allgnetbadgeOnlyWorkers(state, action) {
      state.allgnetbadgeOnlyWorkers = action.payload;
    },
    newsRoomResults(state, action) {
      state.newsRoomResults = action.payload;
    },
    pagesDocuments(state, action) {
      state.pagesDocuments = action.payload;
    },
    employeeContracters(state, action) {
      state.employeeContracters = action.payload;
    },
    badgeOnlyWorkers(state, action) {
      state.badgeOnlyWorkers = action.payload;
    },
  },
});

export const getPeopleOnly = (
  sendHttpRequest: any,
  payload: any
): any => {
  return (dispatch) => {
    dispatch(
      searchSlice.actions.employeeContracters({
        ...SearchInitialState.employeeContracters
      })
    );
    return sendHttpRequest({
      url: peopleSearch,
      method: "POST",
      data: payload,
    }).then((data) => {
      if (payload.employeeType === "employeeContractor") {
        dispatch(
          searchSlice.actions.employeeContracters({
            payload: {
              pageSize: 10,
              pageNumber: 1,
              searchTerm: payload?.searchTerm,
              employeeType: "all",
              sortBy: 'Relevance',
              IsFilterby: true,
              reqDepartmentList: [],
              reqLocationlist: [],
            },
            prevPayload: {},
            results: data?.Response?.employeeContractor || [],
            count: data?.Response?.employeeContractorcount || 0,
            departmentlist: data?.Response?.departmentlist || [],
            locationlist: data?.Response?.locationlist || []
          })
        );
      } else if (payload.employeeType === "badgeonlyworker") {
        dispatch(
          searchSlice.actions.badgeOnlyWorkers({
            ...SearchInitialState.badgeOnlyWorkers,
            results: data?.Response?.badgeworker || [],
            count: data?.Response?.badgeworkerCount || 0
          })
        );
      }
    });
  };
};

export const resetSearch = (): any => {
  return (dispatch) => {
    dispatch(
      searchSlice.actions.employeeContracters({
        ...SearchInitialState.employeeContracters
      })
    );
    dispatch(
      searchSlice.actions.badgeOnlyWorkers({
        ...SearchInitialState.badgeOnlyWorkers
      })
    );
    dispatch(
      searchSlice.actions.allgnetemployeeContracters({
        ...SearchInitialState.allgnetemployeeContracters
      })
    );
    dispatch(
      searchSlice.actions.allgnetbadgeOnlyWorkers({
        ...SearchInitialState.allgnetbadgeOnlyWorkers
      })
    );
  };
};
export const getNewsContent = (
  sendHttpRequest: any,
  searchValue: any,
  lobCode: any,
  event: any
): any => {
  return (dispatch) => {
    const dataPayLoad = {
      searchKeyWord: searchValue,
      lobcode: lobCode,
      location: event?.location,
      pageSize: event.pageSize,
      pageNumber: event.page,
      sortOrder: event.sortOrder,
      categoryName: "allnews",
      bookmarkcontentids: [],
    };
    return sendHttpRequest({
      url: NEWSROOM,
      method: "POST",
      data: dataPayLoad,
      type: "NewsRoom",
    }).then((data) => {
      dispatch(
        searchSlice.actions.newsRoomResults(data?.Response)
      );
    });
  };
};
export const searchActions = searchSlice.actions;
export default searchSlice.reducer;
