/**
 *  Description: This file stores the updated count and the State of the Like and Bookmark Button.

 * @author {Cognizant Technology Solution}
 */

// @flow
import { createSlice } from "@reduxjs/toolkit";

const likeBookmarkState = {
  likes: {},
  bookmarks: {},
  myDashBoard: [],
  showBookmarkPopup: false,
  searchValue:""
};

// $FlowFixMe
const likeBookmarkSlice = createSlice({
  name: "likeBookmark",
  initialState: likeBookmarkState,
  reducers: {
    likes(state, action) {
      if (action.payload && action.payload.length > 0) {
        const searchObj = {};
        for (let el of action.payload) {
          searchObj[el.itemId] = el;
        }
        state.likes = searchObj;
      }
    },
    bookmarks(state, action) {
      if (action.payload && action.payload.length > 0) {
        const searchObj = {};
        for (let el of action.payload) {
          searchObj[el.itemId] = el;
        }
        state.bookmarks = searchObj;
      }
    },
    updateBook(state, action) {
      state.bookmarks = action.payload;
    },
    updateLike(state, action) {
      state.likes = action.payload;
    },
    showBookmarkPopup(state, action) {
      state.showBookmarkPopup = action.payload;
    },
    myDashBoard(state, action) {
      state.myDashBoard = action.payload;
    },
    searchValue(state,action){
      state.searchValue = action.payload;
    }
  },
});

export const updateBookmarks = (
  isbookmarked: boolean,
  bookmarksData: any,
  bookmarkId: any,
  itemId: any
): any => {
  return () => {
    const newLikeBookmarkObject = JSON.parse(JSON.stringify(bookmarksData));
    if (isbookmarked) {
      delete newLikeBookmarkObject[itemId];
    } else {
      newLikeBookmarkObject[itemId] = {...bookmarkId};
    }
    return newLikeBookmarkObject;
  };
};

export const updateLikes = (
  isLiked: boolean,
  likesData: any,
  LikesId: any,
  itemId: any
): any => {
  return () => {
    const newLikeBookmarkObject = JSON.parse(JSON.stringify(likesData));
    if (isLiked) {
      const result = newLikeBookmarkObject[itemId];
      result.likeCount = result.likeCount - 1;
      result.ownLike = false;
      newLikeBookmarkObject[itemId] = result;
    } else {
      newLikeBookmarkObject[itemId] = LikesId;
    }
    return newLikeBookmarkObject;
  };
};

export const getLikesBookmarks = (
  sendHttpRequest: any,
  urlPath: string
): any => {
  return () => {
    return sendHttpRequest({
      url: urlPath,
      type: "LIKES AND BOOKMARKS",
    });
  };
};

export const likeBookmarkActions = likeBookmarkSlice.actions;
export default likeBookmarkSlice.reducer;
