/**
 
 This Page is used for Error Handling. 
 
 * @author {Cognizant Technology Solution}
 */

// @flow
export default function errorCodesHandling(statusCode: number): string {
  let message;
  switch (statusCode) {
    case 400:
      message = "400 Bad Request - Invalid URL.";
      break;
    case 403:
      message =
        "403 Forbidden - The page or resource you were trying to reach is absolutely forbidden for some reason.";
      break;
    case 405:
      message =
        "405 Method not allowed - The procedure to call the page or resource is not recognised.";
      break;
    case 601:
      message =
        "601 Fields missing - Mandatory fields are missing in the request.";
      break;
    case 602:
      message = "602 Invalid format - Request format is invalid.";
      break;
    case 500:
      message =
        "500 Internal server error - The service is unable to connect the database.";
      break;
    case 404:
      message = "404 Not found - Data is not found in the Database.";
      break;
    default:
      message =
        "There was an error while attempting the operation. Please try again later.";
  }
  return message;
}
